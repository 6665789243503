import { useState } from 'react';
import CheckboxInput from '../form/CheckboxInput';
import Guide_DE from "./Guide_DE";

export default function Screen_DE_comming_soon({ onNext }) {
    const [currentStep, setCurrentStep] = useState('x');

    return (<>  
        <div className="fit-house-bg make_header_space">
            <div className="content-wrapper" data-step={currentStep}>
                <div className="step_a">
                    <h1 className="mb-[0.2em] shrink">Pssst… Mach Dich bereit für<br />eine zarte Überraschung</h1>
                    <div className='subhead_x'><strong>Start:</strong> 01.11.2024</div>
                </div>
                <div className="milkulas_wrapper">
                    <img className="milkulas" src="/images/santa.png" alt="santa"/>
                </div>
            </div>
        </div>
    </>);
}