// components/walkthrough/PreviewStepA.js
import React from 'react';
import { setScreen } from '../../../store/slices/walkthroughSlice';
import { setIsStarted } from '../../../external/yord-santa/src/modules/slices/userSlice';

const PreviewStepA = ({ isPhoto, dispatch, handlePlayClick, handleSendMessage, isUploading, uploadError }) => {
    return (
        <div className="preview-widget">
            <span className="subhead_a">Nächster Schritt</span>
            <div className="preview-buttons_wrapper_top">
                <button className="btn shrink" onClick={() => {
                    isPhoto ? dispatch(setScreen('B-photo')) : dispatch(setScreen('B-video'));
                    dispatch(setIsStarted(false));
                }}>
                    Weiter bearbeiten
                </button>
                <button className="btn shrink" onClick={handlePlayClick}>
                    PLAY
                </button>
            </div>
            <div className="preview-buttons_wrapper_bottom form_item">
                <button
                    className={`btn shrink btn-gold btn-loader-placeholder ${isUploading ? 'btn-loader' : ''}`}
                    onClick={handleSendMessage}
                    disabled={isUploading}
                >
                    Senden
                </button>
                {uploadError && (
                    <span className="error mt-2 text-center">Das Senden ist fehlgeschlagen, bitte versuchen Sie es später erneut.</span>
                )}
            </div>
        </div>
    );
};

export default PreviewStepA;