import { useState, useEffect } from 'react';

export default function TextInput({
    id,
    label,
    value,
    onChange,
    onValidated,
    type = 'text',
    required = true,
    pattern = null,
}) {
    const [inputValue, setInputValue] = useState(value || '');
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
        validate(newValue);
    };

    const validate = () => {
        if (required && !inputValue) {
            setErrorMessage(`${label} ist erforderlich`);
            onValidated(false);
        } else if (pattern && !pattern.test(inputValue)) {
            setErrorMessage(`${label} ist ungültig`);
            onValidated(false);
        } else {
            setErrorMessage('');
            onValidated(true);
        }
    };

    return (
        <div className="form_item form_item-text">
            <label htmlFor={id}>{label}</label>
            {errorMessage && <span className="error">{errorMessage}</span>}
            <input
                id={id}
                type={type}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={() => validate()}
            />
        </div>
    );
}
