// utils/setBooth.js

export async function setBooth(form, hash, recaptchaToken) {
    try {
        const apiUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL;

        const payload = {
            video: `/api/videos/${hash}`,
            email: form.email,
            message: form.message,
            name: `${form.first_name} ${form.last_name}`
        };

        const response = await fetch(`${apiUrl}/api/booths`, {
            method: 'POST',
            headers: {
                'Accept': 'application/ld+json',
                'Content-Type': 'application/ld+json'
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload files');
        }

        const data = await response.json();

        return { 
            status: true, 
        };
    } catch (error) {
        console.error('Error sending from booth:', error);
        return { status: false };
    }
}
