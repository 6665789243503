import { useState } from 'react';

export default function TextAreaInput({
    id,
    label,
    value,
    onChange,
    onValidated,
    required = true,
    pattern = null,
}) {
    const [inputValue, setInputValue] = useState(value || '');
    const [errorMessage, setErrorMessage] = useState('');


    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
        validate(newValue);
    };

    const validate = (newValue = inputValue) => {
        if (required && !newValue) {
            setErrorMessage(`${label} ist erforderlich`);
            onValidated(false);
        } else if (pattern && !pattern.test(newValue)) {
            setErrorMessage(`${label} ist ungültig`);
            onValidated(false);
        } else {
            setErrorMessage('');
            onValidated(true);
        }
    };

    return (
        <div className="form_item form_item-textarea">
            <label htmlFor={id}>{label}</label>
            {errorMessage && <span className="error">{errorMessage}</span>}
            <textarea
                id={id}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={() => validate()}
            />
        </div>
    );
}