// components/walkthrough/Screen_DE_B_photo.js
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setScreen, setMessageType, setMessageId, setAuthToken } from '../../store/slices/walkthroughSlice';
import dynamic from 'next/dynamic';
import ReCAPTCHA from 'react-google-recaptcha';
import MessageManager from '../message/MessageManager';
import { uploadPhoto } from '../../utils/uploadPhoto';

import {
    setPhotoDataUrl,
    setStickers,
    resetPhotoEditor,
    setPhotoTaken,
    setMaskDataUrl,
} from '../../store/slices/photoEditorSlice';

const KonvaCanvas = dynamic(() => import('../message/KonvaCanvas'), { ssr: false });

export default function Screen_DE_B_photo() {
    const dispatch = useDispatch();
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const stageRef = useRef(null);
    const containerRef = useRef(null);
    const stickersLayerRef = useRef(null);
    const recaptchaRef = useRef(null);
    const [addedStickerId, setAddedStickerId] = useState(null);

    // Get state from Redux
    const photoDataUrl = useSelector((state) => state.photoEditor.photoDataUrl);
    const stickers = useSelector((state) => state.photoEditor.stickers);
    const dimensions = useSelector((state) => state.photoEditor.dimensions);
    const photoTaken = useSelector((state) => state.photoEditor.photoTaken);

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (err) {
            alert('Unable to access camera. Please check your permissions.');
        }
    };

    useEffect(() => {
        if (!photoTaken) {
            startCamera();
        }
        dispatch(setMessageType('photo'));

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            }
        };
    }, [photoTaken]);

    useEffect(() => {
        const updateCanvasSize = () => {
            if (containerRef.current) {
                const { height } = containerRef.current.getBoundingClientRect();
                containerRef.current.style.setProperty('--canvas-height', height);
            }
        };
        updateCanvasSize();
        window.addEventListener('resize', updateCanvasSize);
        return () => {
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, []);

    const capturePhoto = async () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        if (video && canvas) {
            const context = canvas.getContext('2d');
            const desiredWidth = dimensions.width;
            const desiredHeight = dimensions.height;
            canvas.width = desiredWidth;
            canvas.height = desiredHeight;

            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;
            const videoAspect = videoWidth / videoHeight;
            const desiredAspect = desiredWidth / desiredHeight;

            let sourceWidth, sourceHeight, sourceX, sourceY;

            if (videoAspect > desiredAspect) {
                // Video je širší, ořezat strany
                sourceHeight = videoHeight;
                sourceWidth = videoHeight * desiredAspect;
                sourceX = (videoWidth - sourceWidth) / 2;
                sourceY = 0;
            } else {
                // Video je vyšší, ořezat horní a dolní část
                sourceWidth = videoWidth;
                sourceHeight = videoWidth / desiredAspect;
                sourceX = 0;
                sourceY = (videoHeight - sourceHeight) / 2;
            }

            // Převrácení obrazu horizontálně
            context.save();
            context.scale(-1, 1);
            context.translate(-desiredWidth, 0);

            // Vyplnění pozadí černou barvou (volitelné)
            context.fillStyle = 'black';
            context.fillRect(0, 0, desiredWidth, desiredHeight);

            // Kreslení ořezaného a převráceného video snímku na canvas
            context.drawImage(
                video,
                sourceX,
                sourceY,
                sourceWidth,
                sourceHeight,
                0,
                0,
                desiredWidth,
                desiredHeight
            );

            context.restore();

            const dataUrl = canvas.toDataURL('image/jpeg', 0.9);
            dispatch(setPhotoDataUrl(dataUrl));
            dispatch(setPhotoTaken(true)); // Nastavit photoTaken na true
            if (video.srcObject) {
                video.srcObject.getTracks().forEach((track) => track.stop());
            }

            const captcha_token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
        
            if (!captcha_token) {
                alert('reCAPTCHA verification failed. Please try again.');
                return;
            }

            const result = await uploadPhoto(
                dataUrl,
                captcha_token
            );
            if (result && result.status) {
                dispatch(setMessageId(result.id));
                dispatch(setAuthToken(result.authToken));
            }
        }
    };

    const retakePhoto = async () => {
        dispatch(setPhotoTaken(false));
        dispatch(resetPhotoEditor());
        // startCamera bude zavolán pomocí useEffect, když je photoTaken false
    };

    return (
        <div className="fit-house-bg make_header_space">
            <div className="content-wrapper">
                <div className="canvas-wrapper">
                    <div className="canvas-widget_level">
                        <div className="canvas-frame">
                            <div className="canvas" ref={containerRef}>
                                {photoTaken ? (
                                    <KonvaCanvas
                                        photoDataUrl={photoDataUrl}
                                        stickers={stickers}
                                        setStickers={(newStickers) => dispatch(setStickers(newStickers))}
                                        stageRef={stageRef}
                                        stickersLayerRef={stickersLayerRef}
                                        dimensions={dimensions}
                                        setMaskDataUrl={(maskDataUrl) => dispatch(setMaskDataUrl(maskDataUrl))}
                                        addedStickerId={addedStickerId}
                                    />
                                ) : (
                                    <video
                                        ref={videoRef}
                                        autoPlay
                                        playsInline
                                        muted
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            transform: 'scaleX(-1)',
                                        }}
                                    />
                                )}
                                <canvas ref={canvasRef} style={{ display: 'none' }} />
                            </div>
                        </div>
                        {photoTaken && (
                            <MessageManager
                                stickers={stickers}
                                setStickers={(newStickers) => dispatch(setStickers(newStickers))}
                                dimensions={dimensions}
                                addedStickerId={addedStickerId}
                                setAddedStickerId={setAddedStickerId}
                            />
                        )}
                    </div>
                    {photoTaken ? (
                        <>
                            <div className="canvas-buttons-wrapper">
                                <button className="btn btn-secondary shrink" onClick={retakePhoto}>
                                    Foto erneut aufnehmen
                                </button>
                                <button
                                    className="btn btn-gold shrink"
                                    onClick={() => dispatch(setScreen('C-preview'))}
                                >
                                    Vorschau ansehen
                                </button>
                            </div>
                        </>
                    ) : (
                        <button className="canvas-button take_photo" onClick={capturePhoto}>
                            Foto aufnehmen
                        </button>
                    )}
                    <ReCAPTCHA
                        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                        size="invisible"
                        ref={recaptchaRef}
                    />
                </div>
            </div>
        </div>
    );
}
