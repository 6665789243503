// utils/uploadVideo.js

export async function uploadVideo(blob, recaptchaToken) {
    try {
        const apiUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL;

        const file = new File([blob], 'video.webm');

        const formData = new FormData();
        formData.append('file', file);
        formData.append('captcha', recaptchaToken); 

        const response = await fetch(`${apiUrl}/api/videos`, {
            method: 'POST',
            headers: {
                'accept': 'application/ld+json',
            },
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload files');
        }

        const data = await response.json();
        console.log('Upload successful:', data);

        return { 
            status: true,
            id: data.id,
            authToken: data.updateAuthToken
        };
    } catch (error) {
        console.error('Error uploading files:', error);
        return { 
            status: false,
            id: ''
        };
    }
}