// components/walkthrough/PreviewStepB.js
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMessage } from '../../../utils/getMessage';
import { setScreen } from '../../../store/slices/walkthroughSlice';
import { resetPhotoEditor, setPhotoTaken } from '../../../store/slices/photoEditorSlice';
import { resetVideoEditor, setVideoTaken } from '../../../store/slices/videoEditorSlice';

const PreviewStepB = ({
    isMobile,
    contentHash
}) => {
    const dispatch = useDispatch();
    const isPhoto = useSelector((state) => state.walkthrough.messageType) === "photo";
    const contentLink = `${process.env.NEXT_PUBLIC_URL}/message/${contentHash}`;
    const [ handleCopyPopup, setHandleCopyPopup ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ contentURL, setContentURL ] = useState('');
    const [ contentError, setContentError ] = useState(false);
    const noFreeSantas = useSelector((state) => state.walkthrough.noFreeSantas);

    useEffect(() => {
        const getContentURL = async () => {
            let counter = 0;

            try {
                const initialResult = await getMessage(contentHash);
                if (initialResult && initialResult.status) {
                    const poll = async () => {
                        try {
                            const result = await getMessage(contentHash);
    
                            if (result.fileWithMaskUrl !== "") {
                                setIsLoading(false);
                                setContentURL(result.fileWithMaskUrl);
                            } else {
                                if (counter >= 10) {
                                    setIsLoading(false);
                                    setContentError(true);
                                } else {
                                    counter++;
                                    setTimeout(poll, 1000);
                                }
                            }
                        } catch (error) {
                            console.error("Error during polling:", error);
                            setIsLoading(false);
                            setContentError(true);
                        }
                    };
                    
                    poll();
                } else {
                    setContentError(true);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error in handleSendMessage:", error);
                setIsLoading(false);
            }
        };
    
        getContentURL();
    }, []);

    const handleDownload = () => {
        window.open(contentURL, '_blank');
    };

    const handleCopyLink = () => {
        setHandleCopyPopup(true);
        setTimeout(() => {
            setHandleCopyPopup(false);
        }, 2000);
        navigator.clipboard.writeText(contentLink);
    };

    const handleCreateAnotherMessage = () => {
        if (isPhoto) {
            dispatch(resetPhotoEditor());
            dispatch(setPhotoTaken(false));
        } else {
            dispatch(resetVideoEditor());
            dispatch(setVideoTaken(false));
        }
        location.reload();
    };

    return (
        <div className="preview-message">
            <h1 className="text-big mb-[0.2em] shrink">WIE MÖCHTEST DU DEINE ZARTE NACHRICHT VERSENDEN?</h1>
            <p className="text-big mb-[1em] shrink">
                Welchen Messenger nutzen Deine Liebsten am meisten?
            </p>
            <div className="divider"></div>
            <div className="preview-message-share">
                {isMobile && (
                    <a target='_blank' href={`fb-messenger://share/?link=${contentLink}`}>
                        <img src="/images/icons/icon-share-facebook.png" />
                    </a>
                )}
                <a target='_blank' href={`
                    mailto:?subject=Das zarteste Geschenk: unser Milka Weihnachtsmann
                    &body=Hey, danke, dass du bei unserer Weihnachtsaktion mitgemacht hast!%0A%0ATeile nun deine zarte Weihnachtsbotschaft mit einer besonderen Person, indem du ihr den folgenden Link per WhatsApp, Social Media oder E-Mail schickst:%0A${contentLink}%0A%0A
                `}>
                    <img src="/images/icons/icon-share-email.png" />
                </a>
                <a target='_blank' href={`https://api.whatsapp.com/send?text=Hey, ich habe eine zarte Überraschung für dich!${noFreeSantas ? ``: ` Schau dir die Nachricht bis zum Ende an..`} ${contentLink}`}>
                    <img src="/images/icons/icon-share-whatsapp.png" />
                </a>
            </div>
            <div className="preview-message-buttons">
                <div className='relative'>
                    <button
                        className={`btn preview-button btn-download ${isLoading ? 'btn-preparing' : ''}`}
                        onClick={handleDownload}
                        disabled={isLoading || contentError}
                    >
                        <span className={`${isLoading ? 'invisible' : ''}`}>Herunterladen</span>
                        <img src="/images/icons/icon-download.png" className={`${(isLoading) ? 'invisible' : ''}`}/>
                        {isLoading && <span className="btn-preparing-placeholder"><span>Herunterladen wird vorbereitet</span></span>}
                    </button>
                    {contentError && (
                        <span className="btn-preparing-error error mt-2 text-center">Herunterladen ist nicht verfügbar.</span>
                    )}
                </div>
                <div className='relative'>
                    <button
                        className="btn preview-button btn-copy_link"
                        onClick={handleCopyLink}
                    >
                        <span>LINK KOPIEREN</span>
                        <img src="/images/icons/icon-copy.png" />
                    </button>
                    {handleCopyPopup && (
                        <div className='copy-popup'>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
            <div className="divider no-mob"></div>
            <button
                className="btn btn-milka_mid"
                onClick={handleCreateAnotherMessage}
            >
                EINE WEITERE NACHRICHT ERSTELLEN
            </button>
        </div>
    );
};

export default PreviewStepB;
