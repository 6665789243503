// components/walkthrough/Screen_DE_no_video.js

import { useDispatch, useSelector } from 'react-redux';
import { setPhotoDataUrl, setDimensions, setPhotoTaken } from '../../store/slices/photoEditorSlice';
import { setScreen, setMessageType } from '../../store/slices/walkthroughSlice';

export default function Screen_DE_no_video() {
    const dispatch = useDispatch();
    const dimensions = useSelector((state) => state.photoEditor.dimensions);

    const handleUploadImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = function(e) {
                    const img = new Image();
                    img.onload = function() {
                        // Create a canvas to resize the image
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        // Set canvas dimensions to desired dimensions
                        const desiredWidth = dimensions.width;
                        const desiredHeight = dimensions.height;
                        canvas.width = desiredWidth;
                        canvas.height = desiredHeight;

                        // Calculate scaling and cropping
                        const imageAspectRatio = img.width / img.height;
                        const desiredAspectRatio = desiredWidth / desiredHeight;

                        let sourceWidth, sourceHeight, sourceX, sourceY;

                        if (imageAspectRatio > desiredAspectRatio) {
                            // Image is wider than desired aspect ratio
                            sourceHeight = img.height;
                            sourceWidth = img.height * desiredAspectRatio;
                            sourceX = (img.width - sourceWidth) / 2;
                            sourceY = 0;
                        } else {
                            // Image is taller than desired aspect ratio
                            sourceWidth = img.width;
                            sourceHeight = img.width / desiredAspectRatio;
                            sourceX = 0;
                            sourceY = (img.height - sourceHeight) / 2;
                        }

                        // Draw the image to the canvas
                        ctx.drawImage(
                            img,
                            sourceX,
                            sourceY,
                            sourceWidth,
                            sourceHeight,
                            0,
                            0,
                            desiredWidth,
                            desiredHeight
                        );

                        // Get the data URL of the resized image
                        const dataUrl = canvas.toDataURL('image/jpeg', 0.9);

                        // Dispatch the data URL to Redux store
                        dispatch(setPhotoDataUrl(dataUrl));
                        dispatch(setPhotoTaken(true));
                        dispatch(setMessageType('photo'));
                        dispatch(setScreen('B-photo'));
                    };
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        };
        input.click();
    };

    return (
        <div className="fit-house-bg make_header_space">
            <div className="content-wrapper" data-step='y'>
                <div className="step_a">
                    <h1 className="shrink">
                        <div className="block">Hoppla!</div>
                        <span>Wir konnten keine<br />Kamera finden</span>
                    </h1>
                    <p className="text-big shrink">Bitte lade ein Foto aus Deiner Galerie hoch</p>
                </div>
                <button className="btn btn-gold shrink" onClick={handleUploadImage}>Jetzt hochladen</button>
                <div className="milkulas_wrapper">
                    <img className="milkulas" src="/images/santa.png" alt="santa"/>
                </div>
            </div>
        </div>
    );
}