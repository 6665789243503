import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateForm } from '../../store/slices/milkulasSlice';
import TextInput from '../form/TextInput';

export default function Screen_CZ_B_form({ onNext }) {
    const dispatch = useDispatch();

    const [form, setForm] = useState({
        name: '',
    });

    const [validity, setValidity] = useState({
        name: false,
    });

    const isValid = Object.values(validity).every(Boolean);

    const setValid = (field, isValidField) => {
        setValidity((prevValidity) => ({
            ...prevValidity,
            [field]: isValidField,
        }));
    };

    const handleChange = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (isValid) {
            dispatch(updateForm(form));
            onNext();
        }
    };

    return (
        <div className="screen_cz_b_form">
            <div className="main">
                <div className="main-bg"></div>
                <div className="main-wrapper">
                    <h2>WHAT'S IN YOUR HEART, MILKULÁŠ?</h2>
                    <p className="text-big">Write your praise and create a video</p>

                    <form onSubmit={submitForm}>
                        <TextInput
                            id="name"
                            label="Name"
                            value={form.name}
                            onChange={(value) => handleChange('name', value)}
                            onValidated={(isValidField) => setValid('name', isValidField)}
                            required={true}
                        />

                        <p className="text-big">I’ve heard you deserve praise for your good deeds! Especially for</p>

                        <button className="btn" type="submit">
                            Send message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
