// components/walkthrough/Screen_DE_B_video.js

import { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setScreen, setMessageType, setMessageId, setAuthToken } from '../../store/slices/walkthroughSlice';
import videoService from '../../utils/videoService';
import dynamic from 'next/dynamic';
import ReCAPTCHA from 'react-google-recaptcha';
import { uploadVideo } from '../../utils/uploadVideo';
import { useRouter } from 'next/router';

import {
    setDimensions,
    resetVideoEditor,
    setIsStreamReady,
    setShowSegmentation,
    setVideoTaken,
    setIsRecording,
    setStickers,
    setIsSegmentationLoading,
} from '../../store/slices/videoEditorSlice';

const VideoRenderer = dynamic(() => import('../message/video/VideoRenderer'), { ssr: false });
const VideoRecorder = dynamic(() => import('../message/video/VideoRecorder'), { ssr: false });
const VideoWithStickers = dynamic(() => import('../message/video/VideoWithStickers'), { ssr: false });
const MessageManager = dynamic(() => import('../message/MessageManager'), { ssr: false });

export default function Screen_DE_B_video() {
    const dispatch = useDispatch();
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const isSegmentationLoading = useSelector((state) => state.videoEditor.isSegmentationLoading);
    const router = useRouter();
    const { debug } = router.query;

    const [recordedVideoBlob, setRecordedVideoBlob] = useState(null);
    const recaptchaRef = useRef(null);
    const [addedStickerId, setAddedStickerId] = useState(null);

    const {
        videoTaken,
        videoDataUrl,
        showSegmentation,
        isStreamReady,
        isRecording,
        dimensions,
        stickers,
        maskDataUrl,
    } = useSelector((state) => state.videoEditor);

    const isiOS = () => {
        if (debug !== undefined) {
            return false;
        }
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    const startCamera = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                dispatch(setIsStreamReady(true));
            }
        } catch (err) {
            console.error('Error accessing webcam:', err);
            alert('Unable to access webcam. Please check permissions.');
        }
    }, [dispatch]);

    useEffect(() => {
        if (!videoTaken) {
            startCamera();
        }

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            }
        };
    }, [videoTaken, startCamera]);

    useEffect(() => {
        dispatch(setDimensions());
    }, [dispatch]);

    useEffect(() => {
        if (!showSegmentation) {
            dispatch(setIsSegmentationLoading(false));
        }
    }, [showSegmentation]);

    useEffect(() => {
        const updateCanvasSize = () => {
            if (containerRef.current) {
                const { height } = containerRef.current.getBoundingClientRect();
                containerRef.current.style.setProperty('--canvas-height', height);
            }
        };

        const retrievedBlob = videoService.getVideoBlob();
        if (retrievedBlob && retrievedBlob.size > 0) {
            setRecordedVideoBlob(retrievedBlob);
            dispatch(setVideoTaken(true));
        } else {
            setRecordedVideoBlob(null);
            dispatch(setVideoTaken(false));
        }

        dispatch(setMessageType('video'));
        updateCanvasSize();
        window.addEventListener('resize', updateCanvasSize);
        return () => {
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, []);

    const retakeVideo = () => {
        if (videoDataUrl) {
            URL.revokeObjectURL(videoDataUrl);
        }
        if (maskDataUrl) {
            URL.revokeObjectURL(maskDataUrl);
        }
        dispatch(resetVideoEditor());
        setRecordedVideoBlob(null);
    };

    const handleRecordingComplete = async (blob) => {
        if (recordedVideoBlob) {
            URL.revokeObjectURL(videoDataUrl);
        }
        setRecordedVideoBlob(blob);
        dispatch(setVideoTaken(true));
        dispatch(setIsRecording(false));
        videoService.setVideoBlob(blob);

        const captcha_token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        if (!captcha_token) {
            alert('reCAPTCHA verification failed. Please try again.');
            return;
        }

        const result = await uploadVideo(
            blob,
            captcha_token
        );
        if (result && result.status) {
            dispatch(setMessageId(result.id));
            dispatch(setAuthToken(result.authToken));
        }
    };

    const handleStopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            videoRef.current = null;
        }
    };

    return (
        <div className="fit-house-bg make_header_space">
            <div className="content-wrapper">
                <div className="canvas-wrapper">
                    <div className="canvas-widget_level">
                        <div className="canvas-frame">
                            <div className="canvas" ref={containerRef}>
                                {videoTaken && recordedVideoBlob ? (
                                    <VideoWithStickers addedStickerId={addedStickerId} />
                                ) : (
                                    <>  
                                        {isSegmentationLoading && (
                                            <div className="wand-container">
                                                <div className="wand">
                                                    <div className="magic"></div>
                                                </div>
                                            </div>
                                        )}
                                        
                                        {/* Canvas element */}
                                        <video
                                            ref={videoRef}
                                            autoPlay
                                            playsInline
                                            muted
                                            width={dimensions.width}
                                            height={dimensions.height}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                inset: '0',
                                                transform: 'scaleX(-1)',
                                                zIndex: '-1'
                                            }}
                                        />
                                        <canvas
                                            ref={canvasRef}
                                            width={dimensions.width}
                                            height={dimensions.height}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        {isStreamReady && (
                                            <VideoRenderer
                                                videoRef={videoRef}
                                                canvasRef={canvasRef}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {!isRecording && videoTaken && recordedVideoBlob && (
                            <MessageManager
                                stickers={stickers}
                                setStickers={(newStickers) => dispatch(setStickers(newStickers))}
                                dimensions={dimensions}
                                addedStickerId={addedStickerId}
                                setAddedStickerId={setAddedStickerId}
                            />
                        )}
                        {!isRecording && !videoTaken && !isiOS() && (
                            <div className="msg_manager">
                                <div className="msg_manager-buttons">
                                    <button
                                        className={`msg_manager-btn_bg_change ${
                                            showSegmentation ? 'active' : ''
                                        }`}
                                        onClick={() => {
                                            if (!showSegmentation) {
                                                dispatch(setIsSegmentationLoading(true));
                                            }
                                            dispatch(setShowSegmentation(!showSegmentation));
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {videoTaken ? (
                        <div className="canvas-buttons-wrapper">
                            <button className="btn btn-secondary shrink" onClick={retakeVideo}>
                                Erneut aufnehmen
                            </button>
                            <button
                                className="btn btn-gold shrink"
                                onClick={() => {
                                    dispatch(setScreen('C-preview'))
     
                                    
                                }}
                            >
                                Vorschau ansehen
                            </button>
                        </div>
                    ) : (
                        <VideoRecorder
                            canvasRef={canvasRef}
                            onStopCamera={handleStopCamera}
                            onRecordingComplete={handleRecordingComplete} // Předání callbacku
                        />
                    )}
                    <ReCAPTCHA
                        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                        size="invisible"
                        ref={recaptchaRef}
                    />
                </div>
            </div>
        </div>
    );
}
