// components/message/MessageManager.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMessageAudio } from '../../store/slices/walkthroughSlice';
import audioService from '../../utils/audioService';

const MessageManager = ({ stickers, setStickers, setAddedStickerId }) => {
    const dispatch = useDispatch();
    const messageAudio = useSelector((state) => state.walkthrough.messageAudio);
    const dimensions = useSelector((state) => state.photoEditor.dimensions);
    const [activeMenu, setActiveMenu] = useState(null);

    const iconStickers = [
        { url: '/images/message-stickers/sticker_present.png', alt: 'Present sticker' },
        { url: '/images/message-stickers/sticker_santa.png', alt: 'Santa sticker' },
        { url: '/images/message-stickers/sticker_snowman.png', alt: 'Snowman sticker' },
        { url: '/images/message-stickers/sticker_sock.png', alt: 'Sock sticker' },
        { url: '/images/message-stickers/sticker_tree.png', alt: 'Tree sticker' },
        // Add more stickers as needed
    ];

    const textStickers = [
        { url: '/images/message-stickers/sticker-text-1.png', alt: 'Text 1' },
        { url: '/images/message-stickers/sticker-text-2.png', alt: 'Text 2' },
        { url: '/images/message-stickers/sticker-text-3.png', alt: 'Text 3' },
        { url: '/images/message-stickers/sticker-text-4.png', alt: 'Text 2' },
        { url: '/images/message-stickers/sticker-text-5.png', alt: 'Text 3' },
        // Add more stickers as needed
    ];

    const generateUniqueId = () => {
        return 'sticker_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);
    };

    const addSticker = (sticker, event) => {
        const img = event.target;
        const managerRect = document.querySelector('.msg_manager-overlay').getBoundingClientRect();
        const imgRect = img.getBoundingClientRect();
        const ratio = dimensions.width / managerRect.width;

        const newSticker = {
            ...sticker,
            id: generateUniqueId(),
            x: (imgRect.left - managerRect.left) * ratio,
            y: (imgRect.top - managerRect.top) * ratio,
            rotation: 0,
            scaleX: img.width / img.naturalWidth * ratio,
            scaleY: img.height / img.naturalHeight * ratio,
        };
        setStickers([...stickers, newSticker]);
        setAddedStickerId(newSticker.id);
        setActiveMenu(null);
    };

    useEffect(() => {
        if (messageAudio) {
            audioService.playAudio();
        } else {
            audioService.stopAudio();
        }
        
        return () => {
            audioService.stopAudio();
        };
    }, [messageAudio]);

    const handleToggleAudio = () => {
        dispatch(setMessageAudio(!messageAudio));
    };

    return (
        <div className="msg_manager">
            <div className="msg_manager-buttons">
                <button
                    className={`msg_manager-btn_audio ${messageAudio ? '' : 'muted'}`} 
                    onClick={handleToggleAudio}
                ></button>
                <button
                    className={`msg_manager-btn_icons ${activeMenu === 'icons' ? 'active' : ''}`}
                    onClick={() => setActiveMenu(activeMenu === 'icons' ? null : 'icons')}
                ></button>
                <button
                    className={`msg_manager-btn_text ${activeMenu === 'texts' ? 'active' : ''}`}
                    onClick={() => setActiveMenu(activeMenu === 'texts' ? null : 'texts')}
                ></button>
            </div>
            {activeMenu === 'icons' && (
                <div className="msg_manager-overlay">
                    <button className="msg_manager-close" onClick={() => setActiveMenu(null)} />
                    <div className="msg_manager-icons">
                        {iconStickers.map((sticker, index) => (
                            <img
                                key={index}
                                src={sticker.url}
                                alt={sticker.alt}
                                onClick={(event) => addSticker(sticker, event)}
                                className="sticker-thumbnail sticker-thumbnail-image"
                            />
                        ))}
                    </div>
                </div>
            )}
            {activeMenu === 'texts' && (
                <div className="msg_manager-overlay">
                    <button className="msg_manager-close" onClick={() => setActiveMenu(null)} />
                    <div className="msg_manager-texts">
                        {textStickers.map((sticker, index) => (
                            <img
                                key={index}
                                src={sticker.url}
                                alt={sticker.alt}
                                onClick={(event) => addSticker(sticker, event)}
                                className="sticker-thumbnail sticker-thumbnail-text"
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessageManager;
