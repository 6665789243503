// components/walkthrough/Screen_DE_C_preview.js
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import MilkaScene from '../../external/yord-santa/src/modules/scenes/MilkaScene';
import { setScreen } from '../../store/slices/walkthroughSlice';
import { setGerman } from '../../external/yord-santa/src/modules/slices/languageSlice';
import { isMobile } from 'react-device-detect';
import { uploadMask } from '../../utils/uploadMask';
import { getMessage } from '../../utils/getMessage';
import audioService from '../../utils/audioService';
import videoService from '../../utils/videoService';
import LoadingOverlay from '../../external/yord-santa/src/modules/views/LoadingOverlay';
import { changeCameraModeToOrbit, lockCamera, unlockCamera } from '../../external/yord-santa/src/modules/slices/cameraSlice';
import Debug from '../../external/yord-santa/src/modules/components/Debug';
import Cookies from 'js-cookie';
import PreviewStepA from './steps/PreviewStepA';
import PreviewStepB from './steps/PreviewStepB';
import PreviewStepC from './steps/PreviewStepC';

export default function Screen_DE_C_preview() {
    const dispatch = useDispatch();
    const isPhoto = useSelector((state) => state.walkthrough.messageType) === "photo";
    const dimensions = useSelector((state) => state.photoEditor.dimensions);
    const maskDataUrlPhoto = useSelector((state) => state.photoEditor.maskDataUrl);
    const maskDataUrlVideo = useSelector((state) => state.videoEditor.maskDataUrl);
    const messageAudio = useSelector((state) => state.walkthrough.messageAudio);
    const messageId = useSelector((state) => state.walkthrough.messageId);
    const authToken = useSelector((state) => state.walkthrough.authToken);
    const maskDataUrl = isPhoto ? maskDataUrlPhoto : maskDataUrlVideo;

    const photoDataUrl = useSelector((state) => state.photoEditor.photoDataUrl);
    const [videoSrc, setVideoSrc] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const timerRef = useRef(null);
    const [currentStep, setCurrentStep] = useState('a');
    const [uploadError, setUploadError] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const isStarted = useSelector((state) => state.user.isStarted);
    const [showContinueBtn, setShowContinueBtn] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [previewVideo, setPreviewVideo] = useState(null);
    const pollingInterval = useRef(null);

    useEffect(() => {
        dispatch(setGerman());
        dispatch(changeCameraModeToOrbit());

        const uploadMaskFc = async () => {
            try {
                const response = await uploadMask(
                    messageId,
                    maskDataUrl,
                    messageAudio,
                    authToken
                );
                if (response && response.status) {
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (err) {
                console.error(err);

            }
        };
        uploadMaskFc();

        if (!isPhoto) {
            const retrievedBlob = videoService.getVideoBlob();

            if (retrievedBlob) {
                const blobUrl = URL.createObjectURL(retrievedBlob);
                setVideoSrc(blobUrl);

                return () => {
                    URL.revokeObjectURL(blobUrl);
                };
            }
        }

        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isStarted) {
            dispatch(lockCamera());
            setPlayVideo(true);

            const button = document.querySelector('#play-button');
            if (button) {
                button.click();
            }

            if (messageAudio) {
                audioService.playAudio();
            }

            timerRef.current = setTimeout(() => {
                setShowContinueBtn(true);
                dispatch(unlockCamera());
            }, 2000);
        } else {
            if (messageAudio) {
                audioService.stopAudio();
            }
        }
    }, [isStarted]);

    const toggleVideo = function () {
        const video = document.querySelector('.preview-video');

        if (video.paused) {
            video.play();
        }
    };

    useEffect(() => {
        if (!videoSrc || !previewVideo) return;

        const handleLoadedData = () => {
            const video = document.querySelector(".preview-video");
            video.pause();
            video.removeAttribute('autoplay');
            video.loop = true;
        };

        previewVideo.addEventListener('loadeddata', handleLoadedData);

        return () => {
            previewVideo.removeEventListener('loadeddata', handleLoadedData);
        };
    }, [videoSrc, previewVideo]);

    useEffect(() => {
        const video = document.querySelector(".preview-video");
        if (video) {
            if (video.readyState >= 2) {
                if (playVideo) {
                    video.play().catch((error) => {
                        console.log(`Error playing video: ${error.message}`);
                    });
                } else {
                    video.pause();
                    video.currentTime = 0;
                }
                video.muted = isMuted;
            } else {
                const handleCanPlay = () => {
                    if (playVideo) {
                        video.play().catch((error) => {
                            console.log(`Error playing video after canplay: ${error.message}`);
                        });
                    }
                    video.removeEventListener('canplay', handleCanPlay);
                };
                video.addEventListener('canplay', handleCanPlay);
            }
        }
    }, [playVideo]);

    useEffect(() => {
        if (messageAudio) {
            audioService.setMuted(isMuted);
        }
    }, [isMuted]);

    const handleBtnContinue = () => {
        setShowOverlay(true);
        setShowContinueBtn(false);
        setPlayVideo(false);
        if (messageAudio) {
            audioService.stopAudio();
        }
    };

    const handlePlayClick = () => {
        setShowOverlay(false);
        setShowContinueBtn(true);
        setPlayVideo(true);
        if (messageAudio) {
            audioService.playAudio();
        }
    };

    const handleSendMessage = async () => {
        setIsUploading(true);
        setUploadError(false); // Resetovat případné předchozí chyby
        let counter = 0;

        try {
            const initialResult = await getMessage(messageId);

            if (initialResult && initialResult.status) {
                pollingInterval.current = setInterval(async () => {
                    try {
                        const result = await getMessage(messageId);

                        if (result.videoCheckedAt !== "" && result.audioCheckedAt !== "") {
                            clearInterval(pollingInterval.current);
                            setIsUploading(false);
                            
                            if (!result.audioBadWords && result.videoNsfwScore < 0.6) {   
                                const urlParams = new URLSearchParams(window.location.search);
                                const boothParam = urlParams.get('booth');
                                if (boothParam || Cookies.get('xbooth')) {
                                    setCurrentStep('c-booth');
                                } else {
                                    setCurrentStep('b');
                                }
                            } else {
                                dispatch(setScreen('nsfw'));
                            }

                        } else {
                            if (counter >= 5) {
                                clearInterval(pollingInterval.current);
                                setIsUploading(false);
                                setUploadError(true);
                            }
                            counter++;
                        }
                    } catch (error) {
                        console.error("Error during polling:", error);
                        clearInterval(pollingInterval.current);
                        setIsUploading(false);
                        setUploadError(true);
                    }
                }, 2000);
            } else {
                setIsUploading(false);
                setUploadError(true);
            }
        } catch (error) {
            console.error("Error in handleSendMessage:", error);
            setIsUploading(false);
            setUploadError(true);
        }
    };

    const handleToggleMute = () => {
        setIsMuted(!isMuted);
    };

    return (
        <div className={`preview ${isUploading ? 'loader-overlay' : ''}`} id="root">
            {!showOverlay && (
                <div className="preview-control">
                    {showContinueBtn && (
                        <button 
                            className="btn btn-weiter" 
                            onClick={handleBtnContinue}
                        >WEITER</button>
                    )}
                </div>
            )}
            <Debug/>
            <div className={ showOverlay ? 'hide-next' : ''}></div>
            <MilkaScene >
                <div  style={{ position: 'relative', width: dimensions.width, height: dimensions.height }}>
                    {isPhoto ? (
                        <img
                            src={photoDataUrl}
                            alt="Original"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    ) : (
                        videoSrc && (<>
                            <button id="play-button" onClick={toggleVideo}>Play</button>
                            <video
                                className="preview-video"
                                src={videoSrc}
                                ref={(el) => {
                                    if (el) {setPreviewVideo(el)}
                                }}
                                autoPlay
                                playsInline
                                muted={isMuted}
                                style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'relative' }}
                            />                        
                        </>)
                    )}
                    {maskDataUrl && (
                        <img
                            src={maskDataUrl}
                            alt="Stickers"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    )}
                </div>
            </MilkaScene>
            <LoadingOverlay />
            {showOverlay && (
                <div className="preview-overlay" data-step={currentStep}>
                    <a className="preview-overlay-logo" href="#">
                        <img src="/images/milka.png" alt="Milka" />
                    </a>
                    <div className="preview-content">
                        {currentStep === "a" && (<>
                            <PreviewStepA
                                isPhoto={isPhoto}
                                dispatch={dispatch}
                                handlePlayClick={handlePlayClick}
                                handleSendMessage={handleSendMessage}
                                isUploading={isUploading}
                                uploadError={uploadError}
                            />
                        </>)}
                        {currentStep === "b" && (
                            <PreviewStepB
                                isMobile={isMobile}
                                contentHash={messageId}
                            />
                        )}
                        {currentStep === "c-booth" && (
                            <PreviewStepC
                                contentHash={messageId}
                            />
                        )}
                    </div>
                    <div className="preview-santa"></div>
                </div>
            )}
            <div className="preview-logo">
                <a className="logo" href="#">
                    <img src="/images/milka.png" alt="Milka" />
                </a>
            </div>
            <button
                className={`preview-mute_btn ${isMuted ? 'sound-off' : 'sound-on'}`}
                onClick={handleToggleMute}
            >
            </button>
            {showContinueBtn && <div className="preview-icon_360"></div>}
            <div style={{ display: 'none' }}>
                <a href={photoDataUrl} download="original_photo.png" id="original-photo-link">Download Original Photo</a>
                {maskDataUrl && (
                    <a href={maskDataUrl} download="mask_image.png" id="mask-image-link">Download Mask Image</a>
                )}
            </div>
        </div>
    );
}
