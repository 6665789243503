import { useState } from 'react';

export default function EmailInput({
    id,
    label,
    value,
    onChange,
    onValidated,
    required = true,
}) {
    const [inputValue, setInputValue] = useState(value || '');
    const [errorMessage, setErrorMessage] = useState('');

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange(newValue);
    };

    const validate = () => {
        if (required && !inputValue) {
            setErrorMessage(`${label} ist erforderlich`);
            onValidated(false);
        } else if (!emailPattern.test(inputValue)) {
            setErrorMessage(`${label} ist ungültig`);
            onValidated(false);
        } else {
            setErrorMessage('');
            onValidated(true);
        }
    };

    return (
        <div className="form_item form_item-text">
            <label htmlFor={id}>{label}</label>
            {errorMessage && <span className="error">{errorMessage}</span>}
            <input
                id={id}
                type="email"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={() => validate()}
            />
        </div>
    );
}
