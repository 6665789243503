export default function Screen_CZ_A_start({ onNext }) {
    return (
        <div className="screen_cz_a_start">
            <div className="main">
                <div className="main-bg"></div>
                <div className="main-wrapper">
                    <h1>GOOD DEEDS SHOULD BE REWARDED IN EVERY AGE</h1>
                    <p className="text-big">Write your message to Milkuláš, praise those who were tender to you the whole year and give them a chance to win</p>            
                    <button className="btn" onClick={onNext}>Let's get started</button>
                </div>
            </div>
            <div className="guide">
                <h2>HOW TO PRAISE<br />TENDERNESS</h2>
                <div className="guide-items">
                    <div className="guide-item">
                        <img src="/images/icons/cz_guide_write.png" alt="Write"/>
                        <span className="subhead_a">Write message to Milkuláš</span>
                        <p>Who do you think deserves to be praised and why?</p>
                    </div>
                    <div className="guide-divider"></div>
                    <div className="guide-item">
                        <img src="/images/icons/cz_guide_create.png" alt="Create"/>
                        <span className="subhead_a">Write message to Milkuláš</span>
                        <p>Who do you think deserves to be praised and why?</p>
                    </div>
                    <div className="guide-divider"></div>
                    <div className="guide-item">
                        <img src="/images/icons/cz_guide_share.png" alt="Share"/>
                        <span className="subhead_a">Write message to Milkuláš</span>
                        <p>Who do you think deserves to be praised and why?</p>
                    </div>
                </div>
            </div>
        </div>
    );
}