// utils/uploadMask.js

export function base64ToBlob(base64) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const mimeType = base64.split(',')[0].match(/:(.*?);/)[1];
    return new Blob([byteArray], { type: mimeType });
}

export async function uploadMask(messageId, maskDataUrl, audio, authToken) {
    try {
        const apiUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL;

        const maskBlob = base64ToBlob(maskDataUrl);
        const mask = new File([maskBlob], 'mask.png', { type: 'image/png' });
    
        const formData = new FormData();
        formData.append('mask', mask);
        formData.append('updateAuthToken', authToken);
        if (audio) {
            formData.append('audio', true );
        }

        const response = await fetch(`${apiUrl}/api/videos/${messageId}`, {
            method: 'POST',
            headers: {
                'accept': 'application/ld+json',
            },
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload files');
        }

        const data = await response.json();

        return { 
            status: true, 
        };
    } catch (error) {
        console.error('Error uploading mask:', error);
        return { status: false };
    }
}
