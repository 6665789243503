import { useState, useEffect } from 'react';

export default function CheckboxInput({
    id,
    label,
    checked,
    onChange,
    onValidated,
    required = false,
    isSubmitted = false,
    customErrorMessage,
}) {
    const [isChecked, setIsChecked] = useState(checked || false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (isSubmitted) {
            validate(isChecked);
        }
    }, [isSubmitted]);

    const handleInputChange = (e) => {
        const newChecked = e.target.checked;
        setIsChecked(newChecked);
        onChange(newChecked);
        validate(newChecked);
    };

    const validate = (value) => {
        if (required && !value) {
            setErrorMessage(customErrorMessage || `${label} field is required`);
            onValidated(false);
        } else {
            setErrorMessage('');
            onValidated(true);
        }
    };

    return (
        <div className="form_item form_item-checkbox">
            <input
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={handleInputChange}
            />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }}>
                
            </label>
            {errorMessage && <span className="error">{errorMessage}</span>}
        </div>
    );
}
