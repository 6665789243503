// utils/uploadPhoto.js

export function base64ToBlob(base64) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const mimeType = base64.split(',')[0].match(/:(.*?);/)[1];
    return new Blob([byteArray], { type: mimeType });
}

export async function uploadPhoto(blob, recaptchaToken) {
    try {
        const apiUrl = process.env.NEXT_PUBLIC_DRUPAL_API_URL;

        const fileBlob = base64ToBlob(blob);
        const file = new File([fileBlob], 'camera.jpg', { type: 'image/jpeg' });

        const formData = new FormData();
        formData.append('file', file);
        formData.append('captcha', recaptchaToken); 

        const response = await fetch(`${apiUrl}/api/videos`, {
            method: 'POST',
            headers: {
                'accept': 'application/ld+json',
            },
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload files');
        }

        const data = await response.json();

        return { 
            status: true,
            id: data.id,
            authToken: data.updateAuthToken
        };
    } catch (error) {
        console.error('Error uploading files:', error);
        return { 
            status: false,
            id: ''
        };
    }
}