// components/walkthrough/PreviewStepC.js
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import TextInput from '../../form/TextInput';
import EmailInput from '../../form/EmailInput';
import TextAreaInput from '../../form/TextAreaInput';
import CheckboxInput from '../../form/CheckboxInput';
import { setBooth } from '../../../utils/setBooth';
import { setScreen, setIsBlurred } from '../../../store/slices/walkthroughSlice';


const PreviewStepC = ({ contentHash }) => {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        message: `Teile nun deine zarte Weihnachtsbotschaft mit einer besonderen Person, indem du ihr den folgenden Link per WhatsApp, Social Media oder E-Mail schickst:`,
        agreeToTerms: false
    });
    const [validity, setValidity] = useState({
        first_name: false,
        last_name: false,
        email: false,
        message: true,
        agreeToTerms: false
    });
    const [showPreview, setShowPreview] = useState(false);
    const [showError, setShowError] = useState(false);
    const recaptchaRef = useRef(null);
    const isValid = Object.values(validity).every(Boolean);

    const contentURL = ""; // TODO

    const setValid = (field, isValidField) => {
        setValidity((prevValidity) => ({
            ...prevValidity,
            [field]: isValidField,
        }));
    };

    const handleChange = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));
    };

    const submitForm = async(e) => {
        e.preventDefault();
        if (isValid) {
            setShowPreview(true);
        }
    };

    const sendToBooth = async(e) => {
        const captcha_token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

    
        if (!captcha_token) {
            alert('reCAPTCHA verification failed. Please try again.');
            return;
        }

        const resultBoothApi = await setBooth(
            form,
            contentHash,
            captcha_token
        );

        if (resultBoothApi.status) {
            dispatch(setScreen("booth-typ"));
            dispatch(setIsBlurred(false));
        } else {
            setShowError(true);
        }
    };

    return (
        <div className="booth">
            {!showPreview && (<>
            
                <h1 className="text-big mb-[0.2em] shrink">THIS IS BOOTH!</h1>
                <form className="form" onSubmit={submitForm}>
                    <EmailInput
                        id="email"
                        label="E-Mail-Adresse des Empfängers"
                        value={form.email}
                        onChange={(value) => handleChange('email', value)}
                        onValidated={(isValidField) => setValid('email', isValidField)}
                        required={true}
                        />
                    <TextAreaInput
                        id="userMessage"
                        label="Ihre Nachricht"
                        value={form.message}
                        onChange={(value) => handleChange('message', value)}
                        onValidated={(isValidField) => setValid('message', isValidField)}
                        required={false}
                    />
                    <div className="form-group_2">
                        <TextInput
                            id="first_name"
                            label="Deine Vorname"
                            value={form.first_name}
                            onChange={(value) => handleChange('first_name', value)}
                            onValidated={(isValidField) => setValid('first_name', isValidField)}
                            required={true}
                        />
                        <TextInput
                            id="last_name"
                            label="Deine Nachname"
                            value={form.last_name}
                            onChange={(value) => handleChange('last_name', value)}
                            onValidated={(isValidField) => setValid('last_name', isValidField)}
                            required={true}
                        />
                    </div>
                    <CheckboxInput
                        id="agreeToTerms"
                        label="Ich stimme den <a target='_blank' class='underline' href='/pdf/Allgemeine_geschaftsbedingungen.pdf'>Teilnahmebedingungen</a> zu "
                        checked={form.agreeToTerms}
                        onChange={(value) => handleChange('agreeToTerms', value)}
                        onValidated={(isValidField) => setValid('agreeToTerms', isValidField)}
                        isSubmitted={false}
                        required={true}
                        customErrorMessage="Zustimmung erforderlich"
                    />

                    <button className="btn btn-gold" type="submit">
                        Preview
                    </button>
                </form>
            </>)}
            {showPreview && (<>
                <h1 className="text-big mb-[0.2em] shrink">Draft of the message</h1>
                <div className="booth-draft_email">
                    <div className="p-5 my-5 bg-[#5252526b]">
                        <p><strong>Das zarteste Geschenk: unser Milka Weihnachtsmann</strong></p>
                        <br/>
                        <p>{form.message}</p>
                        <p><a href="${contentURL}" target="_blank">Das zarteste Geschenk: unser Milka Weihnachtsmann</a></p>
                        <br/>
                        <p>Frohe Weihnachten!</p>
                        <br/>
                        <p>Dein {form.first_name} {form.last_name}</p>
                    </div> 
                    <div className="flex justify-center gap-5">
                        <button className="btn" onClick={()=>{ 
                            setShowPreview(false);
                            setShowError(false);
                        }}>
                            Back to draft
                        </button>
                        <button className="btn btn-gold" onClick={sendToBooth}>
                            Senden
                        </button>
                        <ReCAPTCHA
                            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                            size="invisible"
                            ref={recaptchaRef}
                        />
                    </div>
                    {showError && (
                        <span className="error mt-2 text-center">Das Senden ist fehlgeschlagen, bitte versuchen Sie es später erneut.</span>
                    )}
                </div>
            </>)}
        </div>
    );
};

export default PreviewStepC;
