// components/walkthrough/Screen_DE_other_boothTYP.js
import { useEffect } from "react";

export default function Screen_DE_nsfw_content() {
    const handleRefresh = () => {
        location.reload();
    };
    useEffect(() => {
        sessionStorage.clear();
        localStorage.clear();

        const cookies = document.cookie.split("; ");
        for (let cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" + window.location.hostname;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    
        indexedDB.databases().then((dbs) => {
            dbs.forEach((db) => {
                indexedDB.deleteDatabase(db.name);
            });
        });
    }, []);

    return (
        <div className="fit-house-bg make_header_space">
            <div className="content-wrapper">
                <h1 className="shrink mt-10">Sie haben sensible Inhalte wie Video, Foto oder andere audiovisuelle Medien hochgeladen</h1>
                <button className="btn btn-gold shrink inline-block mt-10" onClick={handleRefresh}>EINE WEITERE NACHRICHT ERSTELLEN</button>
                <div className="milkulas_wrapper">
                    <img className="milkulas" src="/images/santa.png" alt="santa"/>
                </div>
            </div>
        </div>
    );
}